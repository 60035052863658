<template>
  <div>
    <div class="container">
      <div class="app-header">
        <img :alt="$config.client.name" :src="logoPath"/>
      </div>
      <div class="form">
        <h5 class="title">{{ $t('forgottenPassword.title') }}</h5>
        <form>
          <div class="form-row">
            <label for="forgottenPassword-email">
              {{ $t('forgottenPassword.message') }}
            </label>
            <input
              v-model="email"
              id="forgottenPassword-email"
              data-test="forgottenPassword-email"
              class="form-control"
              type="text"
              :placeholder="$t('forgottenPassword.placeholder')"
            >
          </div>
          <button
            type="submit"
            class="btn btn-primary"
            data-test="forgottenPassword-reinitPassword"
            @click.prevent="reinitPassword"
          >
            {{ $t('forgottenPassword.confirmationButton') }}
          </button>
          <button
            class="btn btn-outline-secondary"
            data-test="forgottenPassword-SignIn"
            @click="$router.push({ name: 'SignIn' })">
            {{ $t('words.cancel') }}
          </button>
        </form>
      </div>
      <div class="messages">
        <div v-if="error">
          <p class="form-errors">{{ error }}</p>
        </div>
      </div>
    </div>
    <small class="footer">
      <p>{{ $t('footer') }} <a href="https://www.neogeo.fr/" target="_blank" rel="noopener">Neogeo-Technologies</a></p>
    </small>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'ForgottenPassword',
  data() {
    return {
      email: null,
    };
  },

  computed: {
    ...mapState('forgotten-pwd', [
      'error',
      'success'
    ]),

    logoPath() {
      return require(process.env.VUE_APP_LOGO);
    }
  },

  methods: {
    ...mapActions('forgotten-pwd', [
      'REQUEST_FORGOTTEN_PASSWORD'
    ]),
    reinitPassword() {
      this.REQUEST_FORGOTTEN_PASSWORD({
        email: this.email
      })
    }
  }
};
</script>

<style lang="less" scoped>

.container {
  margin: auto;
  width: 480px;
  height: fit-content;

  .form {

    .form-row {
      margin: 0 0 40px 0;
    }

    button.btn {
      float: right;
      position: relative;
      margin-left: 7px;
      margin-right: 0;
    }

    button.btn-primary {
      border: 2px solid #9BD0FF;
      border-radius: 8px;
    }
    button.btn-outline-secondary {
      background-color: #F7F8FA;
      border: 2px solid #A9B2B9;
      border-radius: 8px;
      color: #2F3234;
    }
    button.btn-outline-secondary:hover {
      color: white;
      background-color: #4b4b4b;
    }

  }
  .messages {
    display: inline-block;
    position: relative;
    top: 1.5em;
  }
}

.form-errors {
  color: #EB0600 !important;
}

.form-success {
  color: #11ac45 !important;
}

.footer {
  position: absolute;
  bottom: 0;
  font-size: small;
}

.footer a {
  text-decoration: none;
}

</style>
